/* You can add global styles to this file, and also import other style files */
:root{
  margin: 0;
  padding: 0;
}
app-navbar {
  position: relative;
  z-index: 2;
}

body {
  font-family: var(--font-family);
}

p-table {
  z-index: 0;
}
